import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResultsValidation } from '@beathletics/api-interfaces';
import type { Athlete, Event, Results } from '@prisma/client';
import { EnvService } from '@beathletics/auth';
@Injectable({
  providedIn: 'root',
})
export class ResultApiService {
  #http = inject(HttpClient);
  #env = inject(EnvService);

  apiUrl = this.#env.getEnv('API_URL','/api');

  getAllResults = () => this.#http.get<Results[]>(`${this.apiUrl}/result/all`);

  getAthleteOfTheResult = (athleteId: string) => this.#http.get<Athlete>(`${this.apiUrl}/result/athlete/${athleteId}`);

  getEventOfTheResult = (eventId: string) => this.#http.get<Event>(`${this.apiUrl}/result/event/${eventId}`);

  getResultsDataCounts = () => this.#http.get(`${this.apiUrl}/results/count-data`);

  //! Attention, the bellow update functions are modified to update new resultS table
  updateResultData = (data: Results) => {
    return this.#http.put<Results>(`${this.apiUrl}/results/${data.id}/update`, data);
  };

  updateArrayOfResults = (results: Results[]) => this.#http.put<Results[]>(`${this.apiUrl}/results/update/many`, results);

  updateMultipleValidationStatus = (data: ResultsValidation[]) =>
    this.#http.post<Results[]>(`${this.apiUrl}/results/update-validation`, data);

  deleteResultData = (id: string) => this.#http.delete<Results>(`${this.apiUrl}/result/${id}/delete`);
}
