import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DashboardEvents, DashboardEventsQuery } from '@beathletics/api-interfaces';
import type { Event, Results } from '@prisma/client';
import { EnvService } from '@beathletics/auth';
@Injectable({
  providedIn: 'root',
})
export class EventApiService {
  #http = inject(HttpClient);
  #env = inject(EnvService);

  apiUrl = this.#env.getEnv('API_URL','/api');

  getAllEvents = () => this.#http.get<Event[]>(`${this.apiUrl}/event/all`);

  getLastNDaysEvents = (dashboardQuery: DashboardEventsQuery) =>
    this.#http.get<DashboardEvents>(`${this.apiUrl}/event/dashboard`, {
      params: new HttpParams({ fromObject: dashboardQuery as any }),
    });

  getAllResultOfAnEvent = (eventNumber: string) => this.#http.get<Results[]>(`${this.apiUrl}/event/${eventNumber}/results`);

  getEventDetail = (eventNumber: string) => this.#http.get<Event>(`${this.apiUrl}/event/${eventNumber}`);

  getEventDetailWithXmlCheck = (eventNumber: string) =>
    this.#http.get<{ event: Event; resultsFromXml: boolean }>(`${this.apiUrl}/event/${eventNumber}/withXmlCheck`);

  getEventById = (id: string) => this.#http.get<Event>(`${this.apiUrl}/event/id/${id}`);

  updateEventData = (data: Event) =>
    this.#http.put<Event>(`${this.apiUrl}/event/${data.eventNumber}/update`, {
      data,
    });

  deleteEventData = (eventNumber: string) => this.#http.delete<Event>(`${this.apiUrl}/event/${eventNumber}/delete`);

  deleteResultsOfEvent = (eventId: string) => this.#http.delete<Results[]>(`${this.apiUrl}/event/delete/results/${eventId}`);
}
