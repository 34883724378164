import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Athlete, Results, Organization } from '@prisma/client';
import type { AthleteWithRelations, IAthlete } from '@beathletics/api-interfaces';
import { EnvService } from '@beathletics/auth';
@Injectable({
  providedIn: 'root',
})
export class AthleteApiService {
  #http = inject(HttpClient);
  #env = inject(EnvService);

  apiUrl = this.#env.getEnv('API_URL','/api');

  getAthleteDetail = (liveId: string) => this.#http.get<AthleteWithRelations>(`${this.apiUrl}/athlete/${liveId}`);

  getAthleteById = (id: string) => this.#http.get<Athlete>(`${this.apiUrl}/athlete/id/${id}`);

  getAllResultsOfAnAthlete = (liveId: string) => this.#http.get<Results[]>(`${this.apiUrl}/athlete/${liveId}/results`);

  getOrganizationOfAnAthlete = (orgaID: string) => this.#http.get<Organization>(`${this.apiUrl}/athlete/organization/${orgaID}`);

  deleteAthleteData = (liveId: string) => this.#http.delete<Athlete>(`${this.apiUrl}/athlete/${liveId}/delete`);

  // => NEW MODEL FUNCTIONS

  getAllAthletes = () => this.#http.get<IAthlete[]>(`${this.apiUrl}/athlete/all`);

  getAthleteWithResultsByLiveId = (liveId: string) => this.#http.get<IAthlete>(`${this.apiUrl}/athlete/new/${liveId}`);
}
