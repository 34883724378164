import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type {
  CalendarFilters,
  DataType,
  Discipline,
  FedInsideQuery,
  IAthlete,
  IAthleteFormData,
  IClubResultsExport,
  ICompetition,
  IComputedRules,
  IEventRegistration,
  IProductWithChildrenDto,
  IRankingData,
  IRankingLabelTree,
  IRegistrationDisciplineMailData,
  IRegistrationPendingReminderMailData,
  IRegistrationResponseMailData,
  IRelayTeam,
  KeycloakUser,
  Mail,
  Picto,
  Pictogram,
  PublicSearchResult,
  RankingsFilters,
  RegistrationAthleteInfo,
  Roles,
} from '@beathletics/api-interfaces';
import { AthleteMinimalData, AthleteSearchResult } from '@beathletics/api-interfaces';
import type {
  Athlete,
  Event,
  NewCategory,
  NewClub,
  NewFederation,
  OneDayBib,
  Organization,
  Results,
  User,
} from '@prisma/client';
import { shareReplay } from 'rxjs';
import type Stripe from 'stripe';
import { EnvService } from '@beathletics/auth';
type LeaderBoardDto = {
  gender: string;
  leaderBoardName: string;
  lastName: string;
  firstName: string;
  liveId: string;
  abbr: string;
  rankingPerf: string;
  startDate: string;
  result_type: string;
  windSpeed: string;
  wind_mode: string;
  eventTypeId: string;
  resultId: string;
  leaderBoardSortOrder: number;
};
@Injectable({
  providedIn: 'root',
})
export class ApiServiceService {
  #http = inject(HttpClient);
  #env = inject(EnvService);
  apiUrl = this.#env.getEnv('API_URL', '');
  getAllTranslations = () => this.#http.get<any[]>(this.apiUrl + '/admin/translations');

  processTranslationFile(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.#http.post<number>(`${this.apiUrl}/admin/translations`, formData);
  }

  getAllTypesOfEvent = () => this.#http.get<string[]>(`${this.apiUrl}/event/types`);

  editEventRoles = (eventNumber: string, role: Roles, user: KeycloakUser) =>
    this.#http.put<Event>(`${this.apiUrl}/event/${eventNumber}/role`, { role, user });

  remapCombinedTotalClubs = () => this.#http.get(`${this.apiUrl}/results/remap-totals`);

  addCertifiableFlagOnResults = () => this.#http.get(`${this.apiUrl}/results/certifiable`);

  //* Search

  refreshSearchService(force = false) {
    return this.#http.post<{ seconds: number }>(`${this.apiUrl}/search/athletes/reload`, {
      force,
    });
  }

  publicSearchAthletesOrEvents(searchString: string) {
    return this.#http.get<PublicSearchResult>(`${this.apiUrl}/search/public/${searchString}`);
  }

  findAthleteByNameOrLiveId(
    searchString: string,
    limitTo?: { athletes?: string[]; clubs?: string[]; categories?: string[] },
    athletesToExclude?: string[],
    registrationSearch = false,
  ) {
    return this.#http.post<AthleteSearchResult[]>(`${this.apiUrl}/search/athletes/${searchString}`, {
      limitTo,
      athletesToExclude,
      registrationSearch,
    });
  }

  findEventByEventNumberOrName(searchString: string, limitToClub?: string) {
    return this.#http.post<ICompetition[]>(`${this.apiUrl}/search/events/${searchString}`, { limitToClub });
  }

  getAthletesMinimalData(athletesIds: string[]) {
    return this.#http.post<AthleteMinimalData[]>(`${this.apiUrl}/athlete/data`, athletesIds);
  }

  findForeignAthleteByAffiliation(affiliationNumber: string) {
    return this.#http.get<IAthlete | null>(`${this.apiUrl}/athlete/find/${affiliationNumber}`);
  }

  findOrCreateAthlete(data: IAthleteFormData & { competitionId: string }) {
    return this.#http.post<IAthlete & { affiliationNumber: string }>(`${this.apiUrl}/athlete/find`, data);
  }

  generateBibANdAffiliation(athlete: RegistrationAthleteInfo, competitionId: string) {
    return this.#http.post<OneDayBib>(`${this.apiUrl}/athlete/generate-bib-affiliation/${competitionId}`, athlete);
  }

  generateRelayTeam(data: Omit<IRelayTeam, 'id' | 'number' | 'createdAt'>) {
    return this.#http.post<IRelayTeam>(`${this.apiUrl}/athlete/generate-relay-team/${data.competitionId}`, data);
  }

  //* Organizations (OLD)

  getAllOrganizations() {
    return this.#http.get<Organization[]>(`${this.apiUrl}/organization/all`);
  }

  getCalendarOrganizations() {
    return this.#http.get<Organization[]>(`${this.apiUrl}/organization/calendar`);
  }

  getOrganizationById = (organizationId: string) => {
    return this.#http.get<Organization>(`${this.apiUrl}/organization/id/${organizationId}`);
  };

  getAllAthletesByAbbr(abbrev: string) {
    return this.#http.get<Athlete[]>(`${this.apiUrl}/organization/${abbrev}/athletes`);
  }

  getOrganizationDetail = (organizationAbbr: string) =>
    this.#http.get<Organization>(`${this.apiUrl}/organization/${organizationAbbr}`);

  getOrganizationAthlete = (organizationAbbr: string) =>
    this.#http.get<Organization>(`${this.apiUrl}/organization/${organizationAbbr}/athletes`);

  getOrganizationEvents = (organizationId: string) =>
    this.#http.get<Event[]>(`${this.apiUrl}/organization/${organizationId}/events`);

  // * CLUBS (NEW)

  // ? not used ?
  getRankingsClubsAndFeds = () => this.#http.get<(NewClub | NewFederation)[]>(`${this.apiUrl}/club/rankings`);

  //* Disciplines (OLD)

  getAllDisciplines() {
    return this.#http.get<Discipline[]>(`${this.apiUrl}/discipline/all`);
  }

  getAllUsers() {
    return this.#http.get<User[]>(`${this.apiUrl}/users`);
  }

  //* Event Type

  getResultTypeByCode(code: number) {
    return this.#http.get<string>(`${this.apiUrl}/event-type/result-type/${code}`);
  }

  getAllRacesNationalCodes() {
    return this.#http.get<string[]>(`${this.apiUrl}/event-type/races/codes`);
  }

  //* Pictograms

  getAllPictograms() {
    return this.#http.get<Pictogram[]>(`${this.apiUrl}/pictogram/all`);
  }

  createPictogram(pictogram: Partial<Pictogram>) {
    return this.#http.post<Pictogram>(`${this.apiUrl}/pictogram/add`, pictogram);
  }

  getPictogramById(id: string) {
    return this.#http.get<Picto>(`${this.apiUrl}/pictogram/${id}`);
  }

  //* Mail

  saveOfficialMail(eventId: string, role: Roles, email: string) {
    return this.#http.put(`${this.apiUrl}/event/${eventId}/save-official`, {
      emailData: { role, email },
    });
  }

  sendMail(email: Mail, targetRole: Roles) {
    return this.#http.post<Mail>(`${this.apiUrl}/mail/${targetRole}`, {
      email,
    });
  }

  //* File upload

  processXMLFile({ file, eventNumber, skipSave }: { file: File; eventNumber: string; skipSave: boolean }) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('eventNumber', eventNumber);
    return this.#http.post<any>(`${this.apiUrl}/upload/xml-event/${eventNumber}/${skipSave}`, formData);
  }

  processAlabusFile({ file, eventNumber, skipSave }: { file: File; eventNumber: string; skipSave: boolean }) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('eventNumber', eventNumber);
    return this.#http.post<any>(`${this.apiUrl}/upload/alabus-results/${eventNumber}/${skipSave}`, formData);
  }

  processBestPerfFile(file: File, startDate: number) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.#http.post<any>(`${this.apiUrl}/upload/best-perf/${startDate}`, formData);
  }

  //* Referential File upload

  processReferentialFile(file: File, type: DataType) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.#http.post(`${this.apiUrl}/data-extractor/upload/${type}`, formData);
  }

  //* Results (new schema version)

  saveXmlEventResults(eventNumber: string, results: unknown[]) {
    return this.#http.post(`${this.apiUrl}/results/event-results/${eventNumber}`, {
      body: results,
    });
  }

  getClubPerfListForExport(clubAbbr: string, dateRange?: { from?: Date; to?: Date }) {
    return this.#http.post<IClubResultsExport[]>(`${this.apiUrl}/results/club-perf/${clubAbbr}`, dateRange);
  }

  getVenueInconsistencies() {
    return this.#http.get(`${this.apiUrl}/event-type/venue/inconsistency`);
  }

  getEventResultsWithRelations(eventNumber: string) {
    return this.#http.get<Results[]>(`${this.apiUrl}/results/event-results/fullprisma/${eventNumber}`);
  }

  askFedInsideSync(query: FedInsideQuery) {
    return this.#http.get(`${this.apiUrl}/data-extractor/sync/${query}`);
  }

  askAthleteSync() {
    return this.#http.get(`${this.apiUrl}/data-extractor/sync_web_files`);
  }

  syncFtp() {
    return this.#http.get(`${this.apiUrl}/data-extractor/sync_ftp_results_files`);
  }

  processPendingFtpFiles() {
    return this.#http.get(`${this.apiUrl}/data-extractor/process_ftp_results_files`);
  }

  getResultsLinkCounts() {
    return this.#http.get(`${this.apiUrl}/results/count-data`);
  }

  getResultsMissingKeys(onlyKeys: boolean) {
    return this.#http.get(`${this.apiUrl}/results/missing-keys/${onlyKeys}`);
  }

  connectXmlResultsWithEventType() {
    return this.#http.get(`${this.apiUrl}/results/connect-eventType`);
  }

  connectResultsWithEventTypeJob() {
    return this.#http.get(`${this.apiUrl}/data-extractor/connect-results-with-eventType`);
  }

  setAllAthletesPersonalRecords() {
    return this.#http.get(`${this.apiUrl}/results/personal-records/all`);
  }

  setAthletePersonalRecords(athleteId: string) {
    return this.#http.get(`${this.apiUrl}/results/personal-records/${athleteId}`);
  }

  getPersonalRecordsOfAthletesForDisciplines(data: { [athleteId: string]: string[] }) {
    return this.#http.post<{
      [athleteId: string]: {
        disciplineRef: string;
        personalBest: number | null;
        personalBestDate?: string | null;
        seasonBest: number | null;
        lastSeasonBest: number | null;
      }[];
    }>(`${this.apiUrl}/results/personal-records`, data);
  }

  migrateAllDisciplinesOrigins() {
    return this.#http.get(`/api/discipline/migrate-origin`);
  }

  verifyDisciplinesVenue() {
    return this.#http.get(`${this.apiUrl}/results/disciplines/venue`);
  }

  getHomeInfo() {
    return this.#http.get<{
      totalEvent: number;
      totalAthlete: number;
      totalResult: number;
      totalRecord: number;
    }>(`${this.apiUrl}/homeInfo`);
    //  .pipe(shareReplay());
  }

  getLeaderBoardRows(season: 'I' | 'O') {
    return this.#http.get<{
      leaderFemale: {
        leaderBoardName: string;
        leaderBoardSortOrder: number;
      }[];
      leaderMale: {
        leaderBoardName: string;
        leaderBoardSortOrder: number;
      }[];
    }>(`${this.apiUrl}/board/rows/${season}`);
  }

  getLeaderBoardData(season: 'I' | 'O', year: number) {
    return this.#http
      .get<{
        leaderFemale: LeaderBoardDto[];
        leaderMale: LeaderBoardDto[];
      }>(`${this.apiUrl}/leaderBoard/${season}/${year}`)
      .pipe(shareReplay());
  }

  refreshBestPerfMatView() {
    return this.#http.get<{ seconds: number }>(this.apiUrl + '/results/refresh-materialized-view');
  }

  getTestModel() {
    return this.#http.get(this.apiUrl + '/test-model');
  }

  getTestModel2() {
    return this.#http.get(this.apiUrl + '/test-model2');
  }

  getTestModelAsync() {
    return this.#http.post(this.apiUrl + '/data-extractor/dataJob/copyCompetitions', {});
  }

  loadCalendarWithFilters = (filters: CalendarFilters) => {
    Object.keys(filters).forEach((key) => filters[key] === null && delete filters[key]);
    return this.#http.get<{
      competitions: ICompetition[];
    }>(this.apiUrl + '/new-competition/calendar', {
      params: new HttpParams({ fromObject: filters as any }),
    });
  };

  getAllNewCompetition(): import('rxjs').Observable<ICompetition[]> {
    return this.#http.get<ICompetition[]>(this.apiUrl + '/new-competition/calendar');
  }

  getRankingDisciplinesAndCat = () =>
    this.#http.get<{
      categories: NewCategory[];
      disciplines: IRankingLabelTree[];
    }>(this.apiUrl + '/results/ranking/disciplines-and-categories');

  loadRankingWithFilters = (filters: RankingsFilters) => {
    Object.keys(filters).forEach((key) => filters[key] === null && delete filters[key]);
    return this.#http.get<{ showWind: boolean; ranking: IRankingData[] }>(this.apiUrl + '/results/ranking', {
      params: new HttpParams({ fromObject: filters as any }),
    });
  };

  getRankingWithDobFromFilters = (filters: RankingsFilters) => {
    Object.keys(filters).forEach((key) => filters[key] === null && delete filters[key]);
    return this.#http.get<{ showWind: boolean; ranking: IRankingData[] }>(`${this.apiUrl}/results/ranking/admin`, {
      params: new HttpParams({ fromObject: filters as any }),
    });
  };

  getCurrentOrder(email: string, eventNumber: string) {
    return this.#http.get<IEventRegistration>(
      `${this.apiUrl}/shopManagement/order/pending/${encodeURIComponent(email)}/${eventNumber}`,
    );
  }

  editClientAndSubmitOrder(orderId: string, newEmail: string, pay: boolean) {
    return this.#http.put<IEventRegistration>(`${this.apiUrl}/shopManagement/order/update-and-submit/${orderId}`, {
      newEmail,
      pay,
    });
  }

  getAllOrdersForEvent(eventNumber: string) {
    return this.#http.get<IEventRegistration[]>(`${this.apiUrl}/shopManagement/orders/${eventNumber}`);
  }

  getOrderById(orderId: string) {
    return this.#http.get<IEventRegistration>(`${this.apiUrl}/shopManagement/order/${orderId}`);
  }

  getProductByRef(eventNumber: string) {
    return this.#http.get<IProductWithChildrenDto>(`${this.apiUrl}/shopManagement/product/${eventNumber}`);
  }

  addProductToOrder(orderId: string, productRef: string, metadata: any) {
    return this.#http.put<IEventRegistration>(`${this.apiUrl}/shopManagement/order/items/${orderId}/${productRef}`, {
      metadata,
    });
  }

  addProductsToOrder(orderId: string, products: { productRef: string; metadata: any }[]) {
    return this.#http.put<IEventRegistration>(`${this.apiUrl}/shopManagement/order/items/${orderId}`, products);
  }

  removeProductsFromOrder(itemsId: string[], orderId: string) {
    return this.#http.delete<IEventRegistration>(`${this.apiUrl}/shopManagement/order/items/${orderId}`, {
      body: { itemsId },
    });
  }

  submitOrder(orderId: string, pay: boolean) {
    return this.#http.put<IEventRegistration & { paymentData?: Stripe.Checkout.Session }>(
      `${this.apiUrl}/shopManagement/order/submit/${orderId}`,
      { pay },
    );
  }

  validateOrder(orderId: string, mailData: IRegistrationResponseMailData, force?: boolean) {
    return this.#http.put<IEventRegistration>(`${this.apiUrl}/shopManagement/order/validate/${orderId}`, {
      ...mailData,
      force,
    });
  }

  cancelOrder(orderId: string, mailData?: IRegistrationResponseMailData) {
    return this.#http.put<IEventRegistration>(`${this.apiUrl}/shopManagement/order/cancel/${orderId}`, mailData);
  }

  validateOrderWithSomeRefusals(orderId: string, mailData: IRegistrationResponseMailData) {
    return this.#http.put<IEventRegistration>(
      `${this.apiUrl}/shopManagement/order/validate-with-refusals/${orderId}`,
      mailData,
    );
  }

  sendPendingReminderEmail(mailData: IRegistrationPendingReminderMailData) {
    return this.#http.post(`${this.apiUrl}/shopManagement/order/pending/reminder`, mailData);
  }

  validateOrRefuseDisciplineItems(action: 'validate' | 'refuse', mailData: IRegistrationDisciplineMailData[]) {
    return this.#http.post<{
      itemsRejected: number;
      itemsValidated: number;
      orderCanceled: number;
      orderValidated: number;
    }>(`${this.apiUrl}/shopManagement/order/items/selection/${action}`, mailData);
  }

  updateClientEmail = (id: any, body: { email: string }) =>
    this.#http.put<IEventRegistration>(`${this.apiUrl}/shopManagement/client/${id}`, body);

  checkRulesForAthleteAndEvent = ({ athleteId, eventNumber }: { athleteId: string; eventNumber: string }) => {
    return this.#http.post<IComputedRules>(
      `${this.apiUrl}/new-competition/registrations/rule/computed/${eventNumber}`,
      {
        athleteId,
      },
    );
  };

  addTranslation(lang: string, key: string, value: string) {
    return this.#http.post(`${this.apiUrl}/admin/translations/json/${lang}`, {
      [key]: value,
    });
  }

  mergeAthleteToAnother(idToDelete: string, idToKeep: string) {
    return this.#http.post(`${this.apiUrl}/athlete/merge`, {
      toDelete: idToDelete,
      toKeep: idToKeep,
    });
  }

  payOrder(orderId: string) {
    return this.#http.post<{ url: string }>(`${this.apiUrl}/shopManagement/pay/${orderId}`, {});
  }
}
